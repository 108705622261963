import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from "./../../environments/environment";
import { CookieService } from 'ngx-cookie-service';
import { throwError } from 'rxjs';

@Injectable()

export class ApiService {

    public endpoint = environment.url
    public httpOptions = {
        headers: new HttpHeaders({
            "Content-Type" : "application/json",
        }),
    };
    constructor(private http: HttpClient,private cookie:CookieService) { }
    private extractData(res: Response) {
        let body = res;
        return body || {};
    }

    get(url): Promise<any> {
        return this.http.get(this.endpoint + url, this.getHeaders()).toPromise()
    }
    post(url, body): Promise<any> {
        return this.http.post<any>(this.endpoint + url, body, this.getHeaders()).pipe(
            map(this.extractData),
            catchError(this.handleError)
        ).toPromise()
    }
    put(url, body): Promise<any> {
        return this.http.put<any>(this.endpoint + url, body, this.getHeaders()).pipe(
            map(this.extractData),
            catchError(this.handleError)
        ).toPromise()
    }
    patch(url, body): Promise<any> {
        return this.http.patch<any>(this.endpoint + url, body, this.getHeaders()).pipe(
            map(this.extractData),
            catchError(this.handleError)
        ).toPromise()
    }
    handleError(error) {
        console.log(error)
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(error.error);
    }
    delete(url): Promise<any> {
        return this.http.delete<any>(this.endpoint + url, this.getHeaders()).toPromise()
    }

    getHeaders() {
        if (this.cookie.check('studeoAdmin')) {
            const user = JSON.parse(this.cookie.get('studeoAdmin'))
            // console.log(user.response.token);
            this.httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': user.response.token
                })
            };
        }
        return this.httpOptions
    }

    postWithoutEndpoint(url, body): Promise<any> {
        return this.http.post<any>(url, body, this.getHeaders()).pipe(
            map(this.extractData),
            catchError(this.handleError)
        ).toPromise()
    }

    getwithoutEndpoint(url): Promise<any> {
        return this.http.get(url, this.getHeaders()).toPromise()
    }


}
