import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/login-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
loginForm:FormGroup;
submitted = false;
  constructor(private builder:FormBuilder,private toastr:ToastrService,
              private api :ApiService,private cookie:CookieService,public route:Router) { 
                // window.location.reload()
  }
  get f() { return this.loginForm.controls;}
  ngOnInit(): void {
    this.loginForm = this.builder.group({
      email_id: ['', [Validators.required, Validators.email]],
      password: ['',  Validators.compose([Validators.required,Validators.minLength(6)])],
    });
}
keyDownFunction(event) {
  if ( event.keyCode === 13) {
   this.onLogin();
  }
}
async onLogin(){
  this.submitted=true;
  if(this.loginForm.invalid){
    return;
  }
  try {
    this.loginForm.value.password = Md5.hashStr(this.loginForm.value.password)
    let data = await this.api.post("admin/auth/signin", this.loginForm.value)
    if (data.success==true) {
      await  this.cookie.set('studeoAdmin', JSON.stringify(data), 7); 
      await  this.cookie.set('user_id', data.response.user_id, 7); 
        this.toastr.success(data.message); 
        this.route.navigate(['/pages/dashboard']);
    }
  else{    
    this.toastr.error(data.message);
  }
}
     catch (error) {
      this.toastr.error("email_id must be a valid email")
}
}
}
