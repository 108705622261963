import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api';
import { CookieService } from 'ngx-cookie-service';
import {ActivatedRoute, Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  otp = this.router.snapshot.params["otp"];
  constructor(
    private builder: FormBuilder,
    private toastr: ToastrService,
    private api: ApiService,
    private cookie: CookieService,
    public route: Router,
    private router: ActivatedRoute

  ) {}
  get f() {
    return this.loginForm.controls;
  }
  ngOnInit(): void {
    const nonWhitespaceRegExp: RegExp = new RegExp('\\S');
    this.loginForm = this.builder.group({
      new_password: ['',
      [Validators.required, Validators.minLength(6), Validators.pattern(nonWhitespaceRegExp)]],
      confirm_password: [  '',
      [Validators.required, Validators.minLength(6), Validators.pattern(nonWhitespaceRegExp)]]
    });
  }
  async onLogin() {
    this.submitted = true;
    //console.log(this.ForgetForm.value)
    if(this.loginForm.value.new_password != this.loginForm.value.confirm_password){
      this.toastr.warning("Confirm Password Not Match New Password ");
    }else{
      
    if (this.loginForm.invalid) {
      return;
    } else {
      let map: any = {
        ...this.loginForm.value, otp:this.otp, changeBy:1
      };
      let hash = Md5.hashStr(this.loginForm.value.new_password);
      map['new_password'] = hash;
      let hash1 = Md5.hashStr(this.loginForm.value.confirm_password);
      map['confirm_password'] = hash1;
      //console.log(hash,hash1);
      delete map.confirm_password;
    try {
      let data = await this.api.post('user/change-password',map);
      if (data.success) {
        this.toastr.success(data.message);
        this.loginForm.reset();
    this.submitted = false;

        // setTimeout(() => {
        //   this.route.navigate(['/login']);
        // }, 800);
      } else {
        this.toastr.error(data.message);
      }
    } catch (error) {
      //console.log(error);
    }
  }
  }
  }
}
