import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { Permission } from './directives/master.permission';
import { LoginComponent } from './pages';

const routes: Routes = [
  {
    path:'pages',
    loadChildren:()=>import('./layout/full-layout/full.module').then(m=>m.FullModule)
  },
  {
    path: '',
   loadChildren: () => import('./layout/simple-layout/simple.module').then(m => m.SimpleModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  // {
  //   path: 'permissions',
  //   component: PermissionsComponent,
  // },
  {
    path: 'login',component: LoginComponent,  
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
