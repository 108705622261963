import { NgxSpinnerService } from 'ngx-spinner';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable ,Injector} from '@angular/core';
// import {HttpsService} from './https.service'
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApiService } from './api';
 
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{
 
  constructor(private injector:Injector,
    private spinner:NgxSpinnerService,
    private toastr :ToastrService,
    private route: Router) {
      this.spinner.show()
     }
  intercept(req:any,next:any){
    this.spinner.show()
    let authService = this.injector.get(ApiService)      
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
              this.spinner.hide();
            }
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              // console.log( 'toeknerror',err);
              this.spinner.hide();
              if (err.status === 400) {
                // console.log('here is error');
                this.spinner.hide();
              }
              if (err.status === 401) {
                this.spinner.hide();
                this.toastr.error('Session expired please login again','Login')
                this.route.navigate(['login'])
              }
            }
 
      }),
);
  }
}