// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  cryptoPass: "$OV!6s}m[@Wj%R^q{c&rd-s01lw*z]r#",
  bucket: "video-on-demand-source-dev",
  solver_api: "https://solver.api.staging.studeo.group/ia-module/v1/",
  // url:"https://nimbus.siya.tech:7892/api/",
  // url:"https://nimbus.siya.tech:2987/api/",
  // url:"http://localhost:8000/api/",
  url: "https://dev-api.studeo.group/api/",

  // url: "http://localhost:2986/api/",

  version: "1.2.7",
  accessKeyId: "AKIA3PH4VJIP2SGEZO4N",
  secretAccessKey: "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",
  region: "eu-west-2",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
